import Aurelia from 'aurelia';
import { RouterConfiguration } from '@aurelia/router-lite';
import { AllConfiguration } from '@aurelia-mdc-web/all';
import { InputmaskConfiguration } from 'aurelia-inputmask';
import { MdcInputMaskConfiguration } from '@aurelia-toolkit/mdc-inputmask';
import { ValidationHtmlConfiguration } from '@aurelia/validation-html';
import { ValidationConfiguration } from '@aurelia/validation';
import { MdcValidationControllerFactory } from '@aurelia-mdc-web/validation';
import { AlertConfiguration } from '@aurelia-toolkit/alert';
import { SVGAnalyzer } from '@aurelia/runtime-html';
import './styles/root-progress.scss';

let doubleTouchStartTimestamp = 0;
document.addEventListener("touchstart", function (event) {
  var now = +(new Date());
  if (doubleTouchStartTimestamp + 500 > now) {
    event.preventDefault();
  };
  doubleTouchStartTimestamp = now;
});

import('./components/app/app').then(x => {
  Aurelia
    .register(RouterConfiguration.customize({ useUrlFragmentHash: false, useHref: false, }), AllConfiguration, SVGAnalyzer, ValidationConfiguration, InputmaskConfiguration, AlertConfiguration,
      MdcInputMaskConfiguration, ValidationHtmlConfiguration.customize(o => {
        o.ValidationControllerFactoryType = MdcValidationControllerFactory;
      }))
    // To use HTML5 pushState routes, replace previous line with the following
    // customized router config.
    // .register(RouterConfiguration.customize({ useUrlFragmentHash: false }))
    .app(x.App)
    .start();
});

